import './css/App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SERVICES from './lib/constants/services';
import OurServices from './pages/OurServices';
import Home from './pages/Home/Home';
import { Route, Routes } from 'react-router-dom';
import { PAGES } from './lib/constants/pages';
import Login from './pages/Login/Login';
import UserPage from './pages/UserPage/UserPage';
import OurTeam from './pages/OurTeam';
import CareerPage from './pages/CareerPage';
import JobPage from './pages/JobPage';
import ErrorPage from './pages/ErrorPage';
import GetAQuote from './pages/GetAQuote';
import AdminPage from './pages/AdminPage';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path={PAGES.home} element={<Home/>}/>
        <Route path='/services/:service?' element={<OurServices services={SERVICES}/>}/>
        <Route path={PAGES.login} element={<Login />} />
        <Route path={PAGES.user} element={<UserPage />} />
        <Route path={PAGES.about} element={<OurTeam/>}/>
        <Route path={PAGES.careers} element={<CareerPage/>}/>
        <Route path={PAGES.job} element={<JobPage/>} />
        <Route path={PAGES.quote} element={<GetAQuote/>} />
        <Route path={PAGES.admin} element={<AdminPage/>}/>
        <Route path='*' element={<ErrorPage/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
