import React, { useContext, useState } from 'react'

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import SectionTitle from '../../elements/SectionTitle';
import FormField from '../../elements/FormField';
import Button from '../../elements/Button';
import loginSchema from '../../lib/schemas/login.schema';

export default function Login() {
  const [form, setForm] = useState({ 
    email: '',
    password: ''
  });
  const { login, loginError, setLoginError } = useContext(AuthContext);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await loginSchema.validate(form, { abortEarly: true })
    } catch (error) {
      setLoginError(error.message);
      return;
    }
    
    await login(form.email, form.password);
  }

  const onChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
    setLoginError('');
  }
  
  return (
    <LOGIN>
      <SectionTitle 
        title='Client Login'
        align='center'
      />
      <form>    
        <FormField 
          field='email'
          id='email-address'
          label='Email address'
          type='email'
          placeholder='Email address'
          required
          value={form.email}
          onChange={e => onChange(e)}
        />

        <FormField 
          field='password'
          id='password'
          label='Password'
          type='password'
          placeholder='Password'
          required
          value={form.password}
          onChange={e => onChange(e)}
        />
                            
        <Button large primary onClick={onSubmit}>Login</Button>
        {loginError && <p className='error'>{loginError}</p>}
      </form>        
    </LOGIN>
  )
}

const LOGIN = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding: 0 2rem;

  form {
    border-radius: 1.25rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    padding: 1rem 2rem;

    background-color: var(--grey-background-color);
  }

  .error {
    font-size: 1rem;
    color: var(--error-color);
  }

  @media (min-width: 720px) {
    form {
      width: 40%;
    }
  }  
`;
