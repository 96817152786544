import { faAngleDoubleDown, faBrain, faMedal, faMicroscope, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb, faChartLine, faCloud  } from '@fortawesome/free-solid-svg-icons';


export const NEEDS = [
  {
    title: 'Flexibility',
    icon: faThumbsUp
  },
  {
    title: 'Quality Insights',
    icon: faMedal
  },
  {
    title: 'AI Integration',
    icon: faLightbulb
  },
  {
    title: 'Tailored Training',
    icon: faAngleDoubleDown
  }
]

export const SERVICES = [
  {
    title: 'KPI Dashboards',
    icon: faChartLine,
    link: '/services/0'
  },
  {
    title: 'Two-Way API Integration',
    icon: faCloud,
    link: '/services/2'
  },
  {
    title: 'Custom NLP Models',
    icon: faBrain,
    link: '/services/3'
  },
  {
    title: 'AI Tools & Algorithms',
    icon: faMicroscope,
    link: '/services/4'
  }
]

export const STEPS = [
  {
    title: 'Identify & Devise',
    description: "You talk, we listen. Outline your data and automation needs, and together we'll envision the ideal solution for your needs."
  },
  {
    title: 'Build & Refine',
    description: 'Through an iterative process, we develop the solution, ensuring clarity and speed in turning your vision into reality.'
  },
  {
    title: 'Implement & Optimize',
    description: 'We execute the plan for long-term success, enabling a streamlined process that supports your business growth.'
  }
]