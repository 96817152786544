import TextWithImage from "../components/TextWithImage"
import styled from "styled-components"
import Button from "../elements/Button"
import { useParams } from "react-router-dom"
import { createRef, useEffect, useRef } from "react"
import useNavigateToTop from "../hooks/useNavigateToTop"

const Div = styled.div`
color: #086872;
text-align: center;
width: 70vw;
margin: 0 auto;
    &h1{
        font-size: 2.5rem;
        font-weight: 1000;
    }
    & p{
        margin-top: 2rem;
    }
`

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    & h2{
        margin-top: 3rem;
        margin-bottom: 2rem;
        width: 80%;
    }
    & button{
        margin-bottom: 2rem;
    }
`


function OurServices({services}){
    const { service } = useParams()
    const blocks = []
    const myRefs = useRef([])
    services.forEach((service, index) => {
        myRefs.current[index] = createRef()
    })
    services.forEach((service, index) => {
        blocks.push(<div ref={myRefs.current[index]}><TextWithImage text={service.text} header={service.header} image={service.image}/></div>)
    })

    useEffect(() => {
        if(service){
            myRefs.current[parseInt(service)].current.scrollIntoView()
        }
    }, [])

    return(
        <MainDiv>
            <Div>
                <h1>Our Services</h1>
                <span style={{fontWeight:"bold", paddingBottom:"3rem"}}>When we say full-service in data analytics and AI, we truly mean it.</span>
            </Div>
            {blocks}
            <h2 style={{textAlign:"center", color:"#086872"}}>Learn how Roga Analytics can redefine your data landscape and transform your business.</h2>
            <Button onClick={useNavigateToTop('/consultation')} large>Schedule an Intro Call</Button>
        </MainDiv>
    )
}

export default OurServices;