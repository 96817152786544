import React, { useContext, useEffect, useState } from 'react'

import styled from 'styled-components';

import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import { isTokenExpired } from '../../lib/utils/powerBI';
import { BACKEND_URL } from '../../lib/constants/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import Dashboard from '../../components/Dashboard';
import LoadingSpinner from '../../elements/LoadingSpinner';

export default function UserPage({}) {
  const { user, isUserLoading } = useContext(AuthContext);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [powerBIToken, setPowerBIToken] = useState('');
  const firebaseFunctionUrl = `${BACKEND_URL}/token`;

  useEffect(() => {
    const getData = async () => {
      if (!user) return;

      setIsLoadingToken(true);
      const powerBIToken = localStorage.getItem('powerBIToken');
      const powerBITokenExpiry = localStorage.getItem('powerBITokenExpiry');

      if (powerBIToken && !isTokenExpired(powerBITokenExpiry)) {
        setPowerBIToken(powerBIToken);
        setIsLoadingToken(false);
      } else {
        try {
          const { powerBIToken, powerBITokenExpiry } = (await axios.post(
            firebaseFunctionUrl,
            {dashboards: user.data.dashboards},
            {headers: {'Authorization': `Bearer ${user.accessToken}`}}
          )).data
          if (!powerBIToken || !powerBITokenExpiry) throw new Error('Problem getting token');
          localStorage.setItem('powerBIToken', powerBIToken);
          localStorage.setItem('powerBITokenExpiry', powerBITokenExpiry);
          setPowerBIToken(powerBIToken);
          setIsLoadingToken(false);
        } catch (error) {
          setIsLoadingToken(false);
        }
      }
    }
    getData();
  }, [user])


  return (
    <USER_PAGE>
      <h1>{user?.data.displayName}</h1>
        <div className='dashboards'>
          {isLoadingToken && <LoadingSpinner />}
          {!isUserLoading && !isLoadingToken && powerBIToken && user?.data?.dashboards?.map(dashboard => 
            <Dashboard 
              name={dashboard.name}
              reportId={dashboard.reportId}
              embedUrl={dashboard.embedUrl}
              powerBIToken={powerBIToken}
            />
          )}
        </div>
        <p className='flip-note'>Please flip the phone <FontAwesomeIcon className='icon' icon={faRotateLeft} /></p>
    </USER_PAGE>    
  )
}

const USER_PAGE = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding: 0 2rem;

  & h1 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-color);
  }

  & .flip-note {
    font-size: 1.5rem;
    color: var(--main-color);
  }

  & .dashboards {
    display: none;
  }

  @media (max-height: 600px) {
    & .expand-icon {
      display: none;
    }
  }

  @media (min-width: 720px) {
    & .flip-note {
      display: none;
    }

    & .dashboards {
      width: 100%;
      height: 100%;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  & .flip-note {
    font-size: 1.5rem;
    color: var(--main-color);
  }

  & .dashboards {
    display: none;
  }

  @media (max-height: 600px) {
    & .expand-icon {
      display: none;
    }
  }

  @media (min-width: 720px) {
    & .flip-note {
      display: none;
    }

    & .dashboards {
      width: 100%;
      height: 100%;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
`;