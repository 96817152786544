import { useState, useEffect } from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../lib/firebase";
import AccordionLink from "../elements/AccordionLink";


function UserList(){
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const getUsers = async () => {
            const usersRef = collection(db, "users");
            const userSnapshot = await getDocs(usersRef);
            const userList = userSnapshot.docs.map((doc) => doc.data())
            setUsers(userList);
        }
        getUsers();
    }, [])
    return(
        <div style={{marginTop:"2rem"}}>
            {users.map(user => {
                return(
                <AccordionLink title={user.displayName} children={
                    <div>
                        {user.dashboards.map(dashboard => {
                            return(
                                <div>
                                    <span>{dashboard.name}</span>
                                </div>
                            )
                        })}
                    </div>
                }/>
            )})}
        </div>
    )
}

export default UserList;