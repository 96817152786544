import ExecutiveCard from "../components/ExecutiveCard"
import teamMembers from "../lib/teamMembers"
import styled from "styled-components"
import Button from "../elements/Button"
import background from '../assets/bg2.svg'
import useNavigateToTop from "../hooks/useNavigateToTop"

const Div = styled.div`
display: flex;
flex-flow: column;
align-items: center;
width: 100%;
& h3{
    font-size: 2rem;
    text-align: center;
    color: #086872;
    border-bottom: 3px solid #086872;
    width: 80%;
    margin-bottom: 2rem;
}
@media (min-width: 720px){
    & h3{
        font-size: 2.5rem;
        width: 30%;
    }
}
`

const Grid = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
@media (min-width: 720px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
}
`

const TextDiv = styled.div`
    display: grid;
    place-items: center;
    color: white;
    padding: 1rem 0 16rem 0;
    @media (min-width: 720px){
        padding-bottom: 10rem;
    }
    background-image: url(${background});
    width: 100%;
    min-height: 75vw;
    background-size: cover;
`

const TextInnerDiv = styled.div`
    width: 90%;
    & h1{
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        @media (min-width: 720px){
            font-size: 4rem;
            margin-top: 1 rem;
        }
    }
    
`


function OurTeam(){
    return(
            <Div>
                <TextDiv>
                    <TextInnerDiv>
                        <h1>Who we are</h1>
                        <p>A rapidly growing Israeli hi-tech startup, operating under the aegis of Roga Partners.</p>
                        <h1>What we believe</h1>
                        <p>
                            Our vision is to enable businesses to fully harness the transformative potential of artificial intelligence, specifically 
                            through the application of custom natural language processing models. We fervently believe in the power of 
                            automation and are dedicated to bringing about a future where the power of AI is thoroughly integrated into every 
                            aspect of business operations.
                        </p>
                        <h1>What we do</h1>
                        <p>
                            We specialize in crafting AI tools and custom NLP models, transforming unstructured data into insightful intelligence. 
                            Our comprehensive consultation and implementation services provide seamless integration of AI into your existing systems. Through constant innovation and customization, we create AI solutions you’ll absolutely love.
                        </p>
                    </TextInnerDiv>
                </TextDiv>
                <h3>
                    Meet our <br/> Executive Team
                </h3>
                <Grid>
                    {teamMembers.map((member) => {
                        return(
                        <ExecutiveCard person={member}/>
                        )
                    })}
                </Grid>
            {/* <Button large onClick={useNavigateToTop('/careers')}>Join Our Team &#8594;</Button> */}
            </Div>
    )
}

export default OurTeam