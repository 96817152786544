import { styled } from "styled-components";

const ContentDiv = styled.div`
padding: 1rem;
& p{
    font-size: 2.7rem;
}
& h1{
    font-size: 3rem;
}
@media (min-width: 720px){
    width: 50vw;
}
`

const Img = styled.img`
display: none;
width: 20vw;
height: 20vw;
object-fit: cover;
border-radius: 25px;
@media (min-width: 720px){
    display: block;
}
`

function TextWithBackground({content, background, src}) {
    const Div = styled.div`
    width: 100%;
    min-height: 52vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    text-align: left;
    `
    return (
        <Div>
            <ContentDiv>
                {content}
            </ContentDiv>
            <Img src={src}/>
        </Div>
    );
}


export default TextWithBackground;