import { InlineWidget } from "react-calendly";

import useMediaQuery from "../hooks/useMediaQuery";

function GetAQuote(){
    const isDesktop = useMediaQuery("(min-width: 720px)");
    return(
        <div>
            <h1 style={{textAlign:"center", color:"#086872"}}>Schedule a Free Consultation</h1>
            <InlineWidget url="https://calendly.com/rogaanalytics/30min"
                styles={{height: isDesktop ? "800px" : "1100px"}}
                pageSettings={{
                    primaryColor: '086872',
                    textColor: 'black'
                }}/>
        </div>
    )
}

export default GetAQuote;