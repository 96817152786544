import { useEffect, useRef, useState } from "react"
import {styled}  from "styled-components"
import './css/ExecutiveCard.css'

const Div = styled.div`
position: relative;
width: 65vw;
display: flex;
flex-flow: column;
align-items: center;
@media (min-width: 720px){
    width: 25vw;
}
`

const ImgDiv = styled.div`
width: 40vw;
height: 40vw;
border-radius: 100%;
background-color: #086872;
display: flex;
justify-content: space-around;
align-items: center;
@media (min-width: 720px){
    width: 15vw;
    height: 15vw;
}

`

const Img = styled.img`
width: 33vw;
height: 33vw;
border-radius: 100%;
object-fit: cover;
z-index: 2;
@media (min-width: 720px){
    width: 12vw;
    height: 12vw;
}
`

const ContentDiv = styled.div`
background-color: #086872;
top: -2rem;
z-index: 1;
color: white;
width: 100%;
position: relative;
padding: 2vh 4vw 2vh 4vw;
border-radius: 20px;
text-align: center;
& h1{
    font-size: 1.6rem;
}
& h2{
    font-size: 1rem;
    font-weight: 100;
}
@media (min-width: 720px){
    padding: 2vh 0.2vw 0.5vh 0.2vw;
}
`

const DisappearingDiv = styled.p`
top: -1rem;
background: rgba(238, 240, 241, 0.9);
height: 94%;
width: 101%;
border-radius: 20px;
z-index: 10;
position: absolute;
overflow: scroll;
padding: 2vh 4vw 2vh 4vw;
@media (min-width: 720px){
    height: 94%;
    width: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-align: left;
    padding: 10px;
}
`


function ExecutiveCard({person}){
    const [isShown, setIsShown] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [isHover, setIsHover] = useState(false);

    const divRef = useRef(null);

    const handleClick = (e) => {
        if (!divRef.current.contains(e.target)) {
            setIsShown(false);
        }else{
            if(!isShown){
                setIsShown(true);
            }
        }
    }

    const handleResize = () => {
        if(window.innerWidth > 720){
            setIsMobile(false);
        }else{
            setIsMobile(true);
        }
    }

    useEffect(() => {
        if(window.innerWidth > 720){
            setIsMobile(false);
        }
        document.addEventListener("resize", handleResize, true)
        return () => {
            document.removeEventListener("resize", handleResize, true)
        }
    }, [])

    useEffect(() => {
        if(window.innerWidth < 720){
            document.addEventListener("click", handleClick, true);
        }else{
            document.removeEventListener("click", handleClick, true);
        }
        return () => {
            document.removeEventListener("click", handleClick, true);
        }
    }, [isMobile])

    return(
        <Div ref={divRef} onMouseEnter={() => {
            if(!isMobile){
                setIsHover(true)
            }
            }} onMouseLeave={() => {
                if(!isMobile){
                    setIsHover(false)
                }
            }}
            >
            <ImgDiv>
                <Img src={person.img} alt={`picture of ${person.name}, ${person.title}`}/>
            </ImgDiv>
            <ContentDiv>
                <h1>{person.name}</h1>
                <h2>{person.title}</h2>
            </ContentDiv>
            {/* <DisappearingDiv className={isMobile? "slide-in" : isHover? "hover-in" : "hidden"} style={isMobile ? {display: isShown ? "block" : "none"} : {}}>
                <span style={{fontWeight:"bolder"}}>{person.name}</span><br/>
                <span style={{fontWeight:"bolder"}}>{person.title}</span><br/>
                {person.bio}
            </DisappearingDiv>
            */}
        </Div>
    )
}

export default ExecutiveCard;