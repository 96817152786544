export const onFullScreen = (element) => {
  if (element) {
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      console.log('exit')
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((error) => {
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().catch((error) => {
        });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().catch((error) => {
        });
      }
    }
  }   
}