import chezkie from '../assets/Chezkie.jpg';
import leah from '../assets/Leah.jpg';

const teamMembers = [
    {
        name: "Leah Pollack",
        title: "CEO",
        //bio:"Leah Pollack has been a leader in the outsourcing industry for over a decade, bringing extensive management experience to her role as CEO of Roga Partners. Her deep understanding of the market has enabled the firm to become a major player in the BPO world. Leah’s ability to engineer opportunity and nurture talent, driving success for clients while spearheading growth, are at the forefront of the company’s culture. Leah combines her broad technical knowledge with outstanding business savvy and the highest standards of integrity.",
        img: leah
    },
    {
        name: "Chezkie Glatt",
        title: "Chief Data Officer",
        //bio: "Chezkie Glatt is a husband, father, lecturer and data scientist. He holds a BS in Business Intelligence and Data Analytics, having graduated with the highest honors from the Sy Syms School of Business, as well as an MBA in Artificial Intelligence from TUW and additional advanced certification as a data analyst from ITC. A proven leader and passionate entrepreneur, Chezkie’s ability to architect data-driven successes with the highest levels of integrity make him an invaluable member of our executive team.",
        img: chezkie
    }
]

export default teamMembers;