import React from 'react'

import styled from 'styled-components';

export default function Step({ title, description, index }) {
  return (
    <STEP>
      <h2 className='index'>{index}</h2>
      <div>
        <h3 className='step-title'>{title}</h3>
        <p className='description'>{description}</p>
      </div>
    </STEP>
  )
}

const STEP = styled.li`
  box-shadow: rgb(10, 104, 114) 0px 0px 5px 0px;
  border-radius: 1.25rem;
  border: 2px solid var(--main-color);
  width: 20.625rem;
  height: 13.5rem;

  padding: 0 1.3rem;

  .index {
    position: relative;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: rgb(10, 104, 114) 0px 0px 5px 0px;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;

    font-size: 1.5rem;
    font-weight: 400;
    color: white;

    background-color: var(--main-color);
  }

  .step-title {
    margin-bottom: 0.75rem;

    font-size: 1.125rem;
    font-weight: 400;
    color: var(--main-color);
  }

  .description {
    font-size: 1rem;
    color: var(--dark-text-color);
  }

  @media (min-width: 720px) {
    width: 20vw;
    height: 19.44rem;

    .index {
      bottom: 11.5%;

      width: 4.375rem;
      height: 4.375rem;
    }

    .step-title {
      font-size: 1.25rem;
    }

    .description {
      font-size: 1.125rem;
    }
  }
`;