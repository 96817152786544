export const PAGES = {
  home: '/',
  about: '/about',
  services: '/services',
  careers: '/careers',
 
  user: '/user',
  job: '/job/:id',
  quote: '/consultation',
  admin: '/admin'
}