import React from 'react';

import styled from 'styled-components';

import Button from '../../elements/Button';
import SectionTitle from '../../elements/SectionTitle';
import Service from '../../elements/Service';
import { SERVICES as services } from '../../lib/constants/badges';
import useNavigateToTop from '../../hooks/useNavigateToTop';

export default function Services() {
  return (
    <SERVICES>
      <SectionTitle 
        title='Our Services'
        fontSize='xl'
      />
      <ul>
        {services.map((service, index) =>
          <Service key={index} {...service} />
        )}
      </ul>
      <h1>Discover How Roga Analytics Can Elevate Your Business Performance.</h1>
      <Button large onClick={useNavigateToTop('/consultation')}>Schedule an Intro Call</Button>
    </SERVICES>
  )
}

const SERVICES = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  text-align: center;

  margin-bottom: 5rem;

  ul {
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    list-style: none;

    & li {
      padding: 2rem 0.5rem 2rem 0.5rem;
    }
  }

  h1 {
    margin-top: 2rem;
    font-size: 2rem;
    color: #086872;
    @media (min-width: 720px) {
      font-size: 3rem;
    }
  }
  @media (min-width: 720px) {
    ul {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;