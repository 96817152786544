import { signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import firebaseErrorCodes from "../lib/constants/firebaseErrorCodes";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../lib/constants/pages";
import { getUserData } from "../lib/utils/firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const login = async (email, password) => {
    try {
      setIsUserLoading(true);
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      const errorCode = error.code;
      if (errorCode in firebaseErrorCodes) setLoginError(firebaseErrorCodes[errorCode])
      else {
        setLoginError('Problem logging in');
        setIsUserLoading(false);
      };  
    }; 
  }

  const logout = async () => {
    setUser(null);
    await auth.signOut();
    localStorage.removeItem('powerBIToken');
    localStorage.removeItem('powerBITokenExpiry');
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        try {
          getUserData(authUser.uid).then(userData => {
            setUser({...authUser, data: userData});
            setIsUserLoading(false);
            if (window.location.pathname === PAGES.login) {
              if (userData.role === 'admin') navigate(PAGES.admin);
              else navigate(PAGES.user);
            }
          });
        } catch (error) {
          setLoginError('Problem getting user data');
          setIsUserLoading(false);
        }
        
      } else {
        logout().then(() => {
          if ([PAGES.user, PAGES.admin].includes(window.location.pathname)) navigate(PAGES.login);
        });
      }
    });

    return () => {
      unsubscribe();
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      isUserLoading,
      setIsUserLoading,
      login,
      logout,
      loginError,
      setLoginError
    }}>
      {children}
    </AuthContext.Provider>
  );
};