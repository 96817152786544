import React from 'react'

import styled from 'styled-components';

export default function FormField(props) {
  const {field, id, label, type, placeholder, required, value, onChange} = props;
  return (
    <FORM_FIELD>
      <label htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        name={field}
        type={type}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FORM_FIELD>
  )
}

const FORM_FIELD = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--main-color);
  }

  input {
    outline: none;
    border-radius: 0.5rem;
    border: solid 2px var(--main-color);
    padding: 0.5rem 1rem;
  }
`;