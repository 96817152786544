import Button from "../elements/Button";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faClock, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef } from "react";
import DragDrop from "../elements/DragDrop";
import { useParams } from "react-router-dom";
import { db, storage } from "../lib/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import LoadingSpinner from "../elements/LoadingSpinner";
import applicationSchema from '../lib/schemas/application.schema'
import formDenial from "../lib/formDenial";

function JobPage(){
    const [isApply, setIsApply] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailError, setIsEmailError] = useState(false);
    const emailRef = useRef(null);
    const [name, setName] = useState("");
    const nameRef = useRef(null);
    const [isNameError, setIsNameError] = useState(false);
    const [phone, setPhone] = useState("");
    const phoneRef = useRef(null);
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [hours, setHours] = useState("Morning");
    const [cv, setCv] = useState(null);
    const cvRef = useRef(null);
    const [isCvError, setIsCvError] = useState(false);
    const [coverLetter, setCoverLetter] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {id} = useParams();
    const [job, setJob] = useState({});

    useEffect(() => {
        const getJob = async () => {
            const jobRef = doc(db, "jobs", id);
            const jobSnap = await getDoc(jobRef);
            setJob(jobSnap.data());
        }
        getJob();
    }, [])

    const inputs = [
        {
            label: "Full Name",
            value: name,
            ref: nameRef,
            func: setIsNameError
        },
        {
            label: "Email",
            value: email,
            ref: emailRef,
            func: setIsEmailError
        },
        {
            label: "Phone Number",
            value: phone,
            ref: phoneRef,
            func: setIsPhoneError
        }, 
        {
            label: "cv",
            value: cv,
            ref: cvRef,
            func: setIsCvError
        }
    ]

    const applyHandler = async () => {
        try{
            setIsSubmitting(true);
            const cvId = uuidv4();
            const cvRef = ref(storage, `applicant-cvs/${cvId}`);
            if(!cv){
                const e = new Error("Please upload a CV");
                e.name = "ValidationError"
                throw e;
            }
            const cvUpload = await uploadBytes(cvRef, cv);
            const cvUrl = await getDownloadURL(cvRef);
            const application ={
                name, email, phone, hours, cv: cvUrl, coverLetter, jobId: id, id: cvId
            }
            await applicationSchema.validate(application);
            const applicationRef = doc(db, "applications", cvId);
            await setDoc(applicationRef, application);
            setName("");
            setEmail("");
            setPhone("");
            setHours("Morning");
            setCv(null);
            setCoverLetter("");
            setIsSubmitting(false);
        }catch(err){
            setIsSubmitting(false);
            if (err.name === "ValidationError"){
                formDenial(inputs)
            }
        }
    }

    return(
        <div style={{display:"flex", flexDirection:"column"}}>
            <TitleDiv>
                <h1>{job.title}</h1>
                <DetailsDiv>
                    <h2> <FontAwesomeIcon icon={faLocationDot}/> {job.location}</h2>
                    <h2> <FontAwesomeIcon icon={faBuilding}/> {job.department}</h2>
                    <h2> <FontAwesomeIcon icon={faClock}/> {job.hours}</h2>
                </DetailsDiv>
            </TitleDiv>
            <ApplyDiv>
                <h3 
                onClick={() => {setIsApply(false)}}>Job Details</h3>
                <Button primary onClick={() => {setIsApply(true)}}>Apply</Button>
            </ApplyDiv>
            <p style={{marginLeft:"2rem", marginRight:"2rem", paddingTop:"1rem", display: isApply ? "none" : "block"}}>{job.description}</p>
            <Button primary 
                style={{alignSelf:"center", margin:"2rem 0 2rem 0", display: isApply ? "none" : "block"}}
                onClick={() => {setIsApply(true)}}>
                    Apply
            </Button>
            <FormDiv style={{display: isApply ? "flex" : "none"}}>
                <span>Full Name<span style={{color:"red", marginLeft:"0"}}> *</span></span>
                <input ref={nameRef} type="text" placeholder="Full Name" value={name} onChange={(e) => {
                    setIsNameError(false)
                    setName(e.target.value)
                    }} />
                {isNameError && <FormError>Please enter your full name</FormError>}
                <span>Email Address<span style={{color:"red", marginLeft:"0"}}> *</span></span>
                <input ref={emailRef} type="text" placeholder="Your Email Address" value={email} onChange={(e) => {
                    setIsEmailError(false)
                    setEmail(e.target.value)
                    }} />
                {isEmailError && <FormError>Please enter a valid email address</FormError>}
                <span>Phone Number<span style={{color:"red", marginLeft:"0"}}> *</span></span>
                <input ref={phoneRef} type="text" placeholder="Your Phone Number" value={phone} onChange={(e) => {
                    setIsPhoneError(false)
                    setPhone(e.target.value)
                    }} />
                {isPhoneError && <FormError>Please enter a valid phone number</FormError>}
                <span>Work Hours</span>
                <select value={hours} onChange={(e) => {setHours(e.target.value)}}>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                    <option value="Flexible">Flexible</option>
                </select>
                <span ref={cvRef}>Upload Your CV<span style={{color:"red", marginLeft:"0"}}> *</span></span>
                <DragDrop handler={(file) => {
                    setIsCvError(false)
                    setCv(file)
                    }} file={cv}/>
                {isCvError && <FormError>Please upload a CV</FormError>}
                <span>Most Recent Work Experience</span>
                <textarea value={coverLetter} cols="50" rows="6" onChange={(e) => setCoverLetter(e.target.value)}></textarea>
                {isSubmitting ?
                    <LoadingSpinner style={{alignSelf:"center"}}/>
                    :
                    <Button primary onClick={applyHandler}>Submit</Button>
                }
            </FormDiv>
        </div>
    )
}

const TitleDiv = styled.div`
display: flex;
flex-direction: column;
background-color: #086872;
align-items: center;
color: white;
padding: 2rem;
& h1{
    font-size: 3rem;
    text-align: center;
}
& h2{
    font-size: 1rem;
}
`

const ApplyDiv = styled.div`
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: flex-start;
border-bottom: 1px solid #086872;
padding-bottom: 1vh;
padding-top: 1vh;
& h3{
    margin-left: 2rem;
    cursor: pointer;
    &:active{
        color: #555353;
    }
}    
& button{
    margin-left: 1rem;
    &:hover{
        cursor: pointer;
    }
}
`

const DetailsDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: flex-start;
margin-left: 2rem;
@media (min-width: 720px){
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
}
`
const FormDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    @media (min-width: 720px){
        align-items: flex-start;
    }
    & textarea{
        resize: none;
        width: 90vw;
        height: 30vh;
        border: 1px solid #086872;
        font-family: "Poppins", sans-serif;
        border-radius: 5px;
        @media (min-width: 720px){
            width: 40vw;
            margin-left: 30vw;
        }
    }
    & textarea:focus{
        border: 2px solid #086872;
        outline: none;
    }
    & input{
        font-family: "Poppins", sans-serif;
        padding: 0.5rem;
        border: 1px solid #086872;
        border-radius: 5px;
        width: 90vw;
        @media (min-width: 720px){
            width: 40vw;
            margin-left: 30vw;
        }
    }
    & input:focus{
        border: 2px solid #086872;
        outline: none;
    }

    & span{
        margin-top: 2rem;
        align-self: flex-start;
        color: #086872;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }

    & select{
        font-family: "Poppins", sans-serif;
        padding: 0.5rem;
        border: 1px solid #086872;
        border-radius: 5px;
        color: #086872;
        align-self: flex-start;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }

    & button{
        align-self: center;
        margin-top: 2rem;
    }
`

const FormError = styled.p`
    color: #086872;
    font-weight: bold;
    @media (min-width: 720px){
        margin-left: 30vw;
    }
`


export default JobPage;