import {useState} from "react";
import styled from "styled-components";

function AccordionLink({children, title}){
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
                <h1>{title}</h1>
            </AccordionTitle>
            <AccordionBody style={{display:isOpen? "block":"none"}}>
                {children}
            </AccordionBody>
        </div>
    )
}

const AccordionTitle = styled.div`
    cursor: pointer;
    background-color: #eee;
    text-align: center;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    @media (min-width: 720px){
        width: 80vw;
        margin: 0 auto;
    }
`

const AccordionBody = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    @media (min-width: 720px){
        width: 80vw;
        margin: 0 auto;
    }
`

export default AccordionLink;